import { Content, Wrapper } from './Layout.styled';
import { AppHeader } from '../AppHeader/AppHeader';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <Wrapper>
      <AppHeader />
      <Content>{children}</Content>
    </Wrapper>
  );
};
