import { useQuery } from '@tanstack/react-query';
import { get } from '@/api/api';
import { Venue } from '@/types/VenuesTypes';
import { AxiosResponse } from 'axios';
const URI = 'venues';

const useGetVenues = () => {
  const query = useQuery<AxiosResponse<Venue[]>, Error>({
    queryKey: [URI],
    queryFn: () => get({ uri: `${URI}` }),
  });

  return query;
};

export { useGetVenues };
