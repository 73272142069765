import { useQuery } from '@tanstack/react-query';
import { get } from 'src/api/api';

const URI = 'config';

export interface ConfigAuth {
  client: string;
  clientId: string;
  domain: string;
  platform: string;
}

const useGetConfigAuth = () => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];

  const query = useQuery<ConfigAuth, Error>({
    queryKey: [subdomain],
    queryFn: async () => {
      const response = await get({ uri: `${URI}/auth/${subdomain}` });
      return response.data;
    },
    refetchOnWindowFocus: false,
    retry: 1,
  });

  return query;
};

export { useGetConfigAuth };
