import { getListOptions } from 'src/hooks/api/useListsEndpoint';
import { queryClient } from 'src/main';
import { routesMap } from 'src/routes';

const STATIC_LIST_PATHS = [{ path: 'new', title: 'New List' }];

export const getListName = async (id: string): Promise<string | undefined> => {
  if (STATIC_LIST_PATHS.some((path) => path.path === id)) {
    return STATIC_LIST_PATHS.find((path) => path.path === id)?.title || 'N/A';
  } else {
    try {
      const data = await queryClient.fetchQuery(getListOptions(id));
      return data?.data?.name;
    } catch (error) {
      console.log(error);
      return 'N/A';
    }
  }
};

export const getBreadcrumbsMap = (): {
  [key: string]: string | ((id: string) => Promise<string | undefined>);
} => {
  const dynamicRoutes = {
    [routesMap.listDetails]: async (id: string) => await getListName(id),
    [routesMap.listDetailsAddPeople]: async () => 'Add People',
  };

  const staticBreadcrumbs: { [key: string]: string } = {
    [routesMap.users]: 'Users',
    [routesMap.settings]: 'Settings',
    [routesMap.reports]: 'Reports',
    [routesMap.ticketLogs]: 'Ticket Logs',
    [routesMap.paymentLogs]: 'Payment Logs',
    [routesMap.accessLogs]: 'Access Logs',
    [routesMap.lists]: 'Lists',
    [routesMap.listNew]: 'New List',
    [routesMap.people]: 'People',
    [routesMap.venues]: 'Venues',
    [routesMap.devices]: 'Devices',
    [routesMap.events]: 'Events',
    [routesMap.account]: 'Account',
    [routesMap.preferences]: 'Preferences',
    [routesMap.kiosk]: 'Kiosk',
    [routesMap.connections]: 'Connections',
    [routesMap.sites]: 'Sites',
    [routesMap.emailTemplates]: 'Email Templates',
    [routesMap.ticketGroups]: 'Ticket Groups',
    [routesMap.noPermission]: 'No Permission',
    [routesMap.login]: 'Login',
    [routesMap.assets]: 'Assets',
  };

  return { ...staticBreadcrumbs, ...dynamicRoutes };
};
