import 'antd/dist/reset.css';

import { ConfigProvider } from 'antd';
import { Outlet } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import Layout from '@/components/Layout';

import AppBreadcrumbs from './components/Breadcrumbs';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';
import { GlobalStyles } from './GlobalStyles';
import SidebarDesktop from './components/Sidebar/SidebarDesktop';
import useDeviceType from './utils/useDeviceType';

const App = () => (
  <ThemeProvider>
    <AppContent />
  </ThemeProvider>
);

const AppContent = () => {
  const { themeAlgorithm, customTheme, combinedTheme } = useTheme();
  const { isDesktop } = useDeviceType();

  return (
    <ConfigProvider theme={{ algorithm: themeAlgorithm, ...customTheme }}>
      <StyledThemeProvider theme={combinedTheme}>
        <GlobalStyles theme={combinedTheme} />
        {isDesktop ? <SidebarDesktop /> : null}
        <Layout>
          <AppBreadcrumbs />
          <Outlet />
        </Layout>
      </StyledThemeProvider>
    </ConfigProvider>
  );
};

export default App;
