import { App } from 'antd';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useAppNavigation } from 'src/routes';

import { get, post, put, remove } from '@/api/api';
import { List, ListEdit } from '@/types/ListsTypes';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const URI = 'watchlists';

const useGetLists = () => {
  const query = useQuery<List[], Error>({
    queryKey: ['watchlists'],
    queryFn: async () => {
      const response = await get({ uri: `${URI}` });
      return response.data;
    },
    staleTime: 1000 * 60, // 1 minute, need to think about how often to refresh // might be because of modal where its called is outside a component tree?
  });

  return query;
};

export const getListOptions = (id: string) => ({
  queryKey: ['watchlists', id],
  queryFn: () => get({ uri: `${URI}/${id}` }),
  enabled: !!id,
});

const useGetList = (id?: string) => {
  const query = useQuery(getListOptions(id as string));

  return query;
};

const createList = async (data: ListEdit) => {
  const newList = {
    name: data.name,
    context: 'accesscontrol', // api does not return lists without context, so default accesscontrol set here (should be set by backend)
  };
  const response = await post({ uri: URI, data: newList });
  return response.data;
};

const updateList = async (data: ListEdit, id?: string) => {
  const newList = {
    name: data.name,
  };
  if (!id) return;
  const response = await put({ uri: `${URI}/${id}`, data: newList });
  return response.data;
};

const useCreateList = () => {
  const { message } = App.useApp();
  const { t } = useTranslation();
  const { navigateTo } = useAppNavigation();
  return useMutation({
    mutationFn: (data: ListEdit) => createList(data),
    onSuccess: (data: List) => {
      message.success(`${data.name} ${t('general.createSuccess')}`);
      navigateTo.listDetails(data.id);
    },
    onError: () => {
      message.error(`${t('general.createFailure')} ${t('lists.list')}.`);
    },
  });
};

const useUpdateList = (id?: string) => {
  const { message } = App.useApp();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ListEdit) => updateList(data, id),
    onSuccess: (updatedList: List) => {
      queryClient.setQueryData(['watchlists', id], (oldData: AxiosResponse<List, List> | undefined) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          data: {
            ...oldData.data,
            name: updatedList.name,
          },
        };
      });
      message.success(`${updatedList.name} ${t('general.updateSuccess')}`);
    },
    onError: () => {
      if (!id) console.warn('id is undefined for list.');
      message.error(`${t('general.updateFailure')} ${t('lists.list')}.`);
    },
  });
};

const deleteList = async (id: string) => {
  const response = await remove({ uri: `${URI}/${id}` });
  return response.data;
};

const useDeleteList = () => {
  const { navigateTo } = useAppNavigation();
  const { message } = App.useApp();
  const { t } = useTranslation();
  return useMutation({
    mutationFn: (id: string) => deleteList(id),
    onSuccess: () => {
      message.success(`${t('lists.list')} ${t('general.deleteSuccess')}`);
      navigateTo.lists();
    },
    onError: () => {
      message.error(`${t('general.deleteFailure')} ${t('lists.list')}.`);
    },
  });
};

export { useCreateList, useDeleteList, useGetList, useGetLists, useUpdateList };
