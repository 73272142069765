import { Auth0Provider } from '@auth0/auth0-react';
import SuspenseLoader from '../SuspenseLoader';
import { useGetConfigAuth } from 'src/hooks/api/useConfigEndpoint';
import Auth0Wrapper from './Auth0Wrapper';

interface Auth0WrapperProps {
  children: React.ReactNode;
}

const Auth0ProviderWrapper = (props: Auth0WrapperProps) => {
  const { children } = props;
  const { data, isLoading, isError } = useGetConfigAuth();

  if (isLoading && !isError) {
    return <SuspenseLoader />;
  }

  const domain = data?.domain || '';
  const clientId = data?.clientId || '';
  const authorizationParams = {
    redirect_uri: window.location.origin,
    audience: 'https://panthers.draco.access.wicketsoft.com/api',
  };

  return (
    <Auth0Provider domain={domain} clientId={clientId} authorizationParams={authorizationParams}>
      <Auth0Wrapper data={data}>{children}</Auth0Wrapper>
    </Auth0Provider>
  );
};

export default Auth0ProviderWrapper;
