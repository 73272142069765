import type { ThemeConfig } from 'antd';

import { MENU_TOKENS } from './components/Sidebar/Menu/Menu.styled';

const FONTS = {
  fontFamily: 'Albert sans, sans-serif',
  fontSize: 15,
  fontSizeLG: 17,
  fontSizeSM: 13,
  fontSizeHeading3: 25,
  fontSizeHeading4: 21,
  fontSizeHeading5: 17,
  fontWeightStrong: 500,
};

const MARGINS = {
  marginXS: 8,
};

const PADDINGS = {
  paddingXS: 8,
  paddingSM: 12,
};

const BORDER_RADIUS = {
  borderRadiusSM: 4,
};

const BOX_SHADOWS = {
  boxShadowSecondary:
    '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);',
};

const DEFAULT_SETTINGS = {
  ...MARGINS,
  ...PADDINGS,
  ...BORDER_RADIUS,
  ...FONTS,
  ...BOX_SHADOWS,
};

export const lightTheme: ThemeConfig = {
  token: {
    colorPrimary: '#1bb5aa',
    colorBgLayout: '#f4f4f4',
    colorBgContainer: '#ffffff',
    colorInfo: '#383d3d',
    colorTextDescription: '#00000073',
    colorSuccessBg: '#e9f9f5', // could not find this color in the design
    colorSuccessHover: '#2cc277', // same here
    colorSuccess: '#2cc277',
    colorWarning: '#ffba00',
    colorError: '#f54761',
    colorBgBase: '#ffffff',
    colorBgElevated: '#ffffff',
    colorSplit: 'rgba(0, 0, 0, 0.06)',
    colorPrimaryBorder: '#8adbce',
    colorBorderSecondary: '#F0F0F0',
    controlItemBgActive: '#e9f9f5',
    colorFillQuaternary: 'rgba(0, 0, 0, 0.02)',
    colorTextHeading: 'rgba(0, 0, 0, 0.88)',
    colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
    colorText: 'rgba(0, 0, 0, 0.88)',
    ...DEFAULT_SETTINGS,
  },
  components: {
    Menu: MENU_TOKENS.light,
  },
};

export const darkTheme: ThemeConfig = {
  token: {
    colorPrimary: '#39b2a6',
    colorBgLayout: '#0B0D0E',
    colorBgContainer: '#131617',
    colorInfo: '#39b2a6',
    colorTextDescription: '#ffffff73',
    colorSuccessBg: '#12231F',
    colorSuccessBorder: '#1B4934',
    colorSuccess: '#28a869',
    colorWarning: '#dca103',
    colorError: '#da455c',
    colorBgBase: '#1a1e24',
    colorBgElevated: '#1C2021',
    colorSplit: 'rgba(255, 255, 255, 0.10)',
    colorPrimaryBorder: '#1a6260',
    colorBorderSecondary: '#313439',
    controlItemBgActive: '#133134',
    colorFillQuaternary: 'rgba(255, 255, 255, 0.04)',
    colorTextHeading: 'rgba(255, 255, 255, 0.85)',
    colorTextDisabled: 'rgba(f, f, f, 0.25)',
    colorText: 'rgba(255, 255, 255, 0.85)',
    colorTextTertiary: '#7D7F7F',
    ...DEFAULT_SETTINGS,
  },
  components: {
    Menu: MENU_TOKENS.dark,
  },
};
