import { ErrorWithMessage } from 'src/api/types';

import { axios } from './axios';

interface ApiParams {
  uri: string;
  data?: unknown;
  headers?: Record<string, string>;
}

const handleRequest = async <T>(request: Promise<T>): Promise<T> => {
  try {
    const response = await request;
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const onError = (error: ErrorWithMessage) => {
  console.error('Something went wrong:', error.response.data.message ?? 'Unknown error');
};

const get = async ({ uri }: ApiParams) => handleRequest(axios.get(uri));

const post = async ({ uri, data, headers }: ApiParams) => handleRequest(axios.post(uri, data, { headers }));

const put = async ({ uri, data }: ApiParams) => handleRequest(axios.put(uri, data));

const patch = async ({ uri, data }: ApiParams) => handleRequest(axios.patch(uri, data));

const remove = async ({ uri }: ApiParams) => handleRequest(axios.delete(uri));

export { get, onError, patch, post, put, remove };
