import { Avatar, Button, Flex, Typography } from 'antd';
import { Header, StyledBellOutlined } from './AppHeader.styled';
import { useGetUser } from '@/hooks/api/useUsersEndpoint';
import VenuesDropdown from './VenuesDropdown';
import useDeviceType from 'src/utils/useDeviceType';
import SidebarMobile from '../Sidebar/SidebarMobile';
import { UserOutlined } from '@ant-design/icons';

export const AppHeader = () => {
  const { isDesktop } = useDeviceType();
  const { data, isSuccess } = useGetUser();

  const fullName = isSuccess ? `${data.firstName} ${data.lastName}` : '';

  const onBellHandler = () => console.log('bell clicked');

  return (
    <Header>
      {!isDesktop ? <SidebarMobile /> : null}
      <VenuesDropdown />
      <Flex align="center" gap={16}>
        <Button type="text" onClick={onBellHandler} icon={<StyledBellOutlined />} />
        {isDesktop ? <Typography.Text>{fullName}</Typography.Text> : null}

        <Avatar src="" icon={<UserOutlined />} />
      </Flex>
    </Header>
  );
};
