import { useQuery } from '@tanstack/react-query';
import { get } from '@/api/api';
import { User } from '@/types/UsersTypes';
import { useAuth } from '@/contexts/AuthContext';

const URI = 'users';

const useGetUser = () => {
  const { username } = useAuth();

  const query = useQuery<User, Error>({
    queryKey: [URI, username],
    queryFn: async () => {
      const response = await get({ uri: `${URI}/${username}` });
      return response.data;
    },
    enabled: !!username,
  });

  return query;
};

export { useGetUser };
