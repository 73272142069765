import { useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

export const routesMap = {
  landing: '/',
  users: '/users',
  settings: '/settings',
  reports: '/reports',
  ticketLogs: '/ticket-logs',
  paymentLogs: '/payment-logs',
  accessLogs: '/access-logs',
  lists: '/lists',
  listNew: '/lists/new',
  listDetails: '/lists/:id',
  listDetailsAddPeople: '/lists/:id/add-people',
  people: '/people',
  venues: '/venues',
  devices: '/devices',
  events: '/events',
  account: '/account',
  preferences: '/preferences',
  kiosk: '/kiosk',
  connections: '/connections',
  sites: '/sites',
  emailTemplates: '/email-templates',
  ticketGroups: '/ticket-groups',
  noPermission: '/no-permission',
  login: '/login',
  assets: '/assets',
};

type RoutesMapKeys = keyof typeof routesMap;

export const useAppNavigation = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const navigateTo = useMemo(
    () => ({
      landing: () => navigate(routesMap.landing),
      users: () => navigate(routesMap.users),
      settings: () => navigate(routesMap.settings),
      reports: () => navigate(routesMap.reports),
      ticketLogs: () => navigate(routesMap.ticketLogs),
      paymentLogs: () => navigate(routesMap.paymentLogs),
      accessLogs: () => navigate(routesMap.accessLogs),
      lists: () => navigate(routesMap.lists),
      listDetails: (id?: string) => {
        if (id) {
          return navigate(routesMap.listDetails.replace(':id', id));
        }
        return navigate(routesMap.lists);
      },
      listDetailsAddPeople: (id?: string) => {
        if (id) {
          return navigate(routesMap.listDetailsAddPeople.replace(':id', id));
        }
        return navigate(routesMap.lists);
      },
      listNew: () => navigate(routesMap.listNew),
      people: () => navigate(routesMap.people),
      venues: () => navigate(routesMap.venues),
      devices: () => navigate(routesMap.devices),
      events: () => navigate(routesMap.events),
      account: () => navigate(routesMap.account),
      preferences: () => navigate(routesMap.preferences),
      kiosk: () => navigate(routesMap.kiosk),
      connections: () => navigate(routesMap.connections),
      sites: () => navigate(routesMap.sites),
      emailTemplates: () => navigate(routesMap.emailTemplates),
      ticketGroups: () => navigate(routesMap.ticketGroups),
      noPermission: () => navigate(routesMap.noPermission),
      assets: () => navigate(routesMap.assets),
      login: () => navigate(routesMap.login),
    }),
    [id],
  );

  return { navigateTo, routesMap };
};

export type { RoutesMapKeys };
